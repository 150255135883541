import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function NavbarLink({ link, name, faI }) {
  return (
    <li id="side-li" className="bg-primary">
      <Link
        to={link}
        rel="noopener noreferrer"
        href="#"
        className="w-fit pr-5 flex items-center p-2 space-x-3 rounded-md"
      >
        <FontAwesomeIcon icon={faI} />
        <span>{name}</span>
      </Link>
    </li>
  );
}

export default NavbarLink;
