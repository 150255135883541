import React, { useState } from "react";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";

const AddNewVoucherCode = ({ rechargeOption, setAddVoucher, setUpdate }) => {
  const [user] = useAuthState(auth);
  const [code, setCode] = useState("");
  //to update
  const addNew = (id) => {
    if (!code) {
      return toast.error("Enter the unipin first!");
    }
    const voucher = {
      adminEmail: user?.email,
      code: code,
      rechargeId: rechargeOption?._id,
    };
    //   console.log(detailsOfPackage, typeof amountInNumber);
    //   posting
    fetch("https://backend.topuphero.com/api/admin/insert-voucher-code", {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "content-type": "application/json",
      },
      body: JSON.stringify(voucher),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.result) {
          setAddVoucher(null);
          setUpdate(data?.result);
          setCode("");
          toast.success("New voucher code is added.");
        } else {
          toast.error(data?.message);
        }
      });
  };
  return (
    <div>
      <input
        type="checkbox"
        id="add-new-voucher-code"
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          {" "}
          <h1 className="font-semibold text-xl py-2">Add New Voucher Code</h1>
          <h1 className="font-bold text-xl">
            You're adding a new unipin to the{" "}
            <span className="text-primary">{rechargeOption?.productName}</span>{" "}
            product. The recharge option is{" "}
            <span className="text-primary">{rechargeOption?.name}</span> and
            price is{" "}
            <span className="text-primary">{rechargeOption?.amount}</span>
          </h1>
          <div className="inline-block w-full">
            <label className="relative block w-full">
              <span className="text-[19px] font-semibold text-gray-900">
                Unipin
              </span>
              <div className="relative inline-block w-full">
                <input
                  required
                  autoComplete="off"
                  value={code}
                  type="text"
                  onChange={(e) => setCode(e.target.value)}
                  name="amount"
                  className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer"
                  placeholder="Enter pin here"
                />
              </div>
            </label>
          </div>
          <div className="modal-action">
            <label
              htmlFor="add-new-voucher-code"
              className="btn bg-green-800 text-white"
            >
              No
            </label>
            <label
              htmlFor="add-new-voucher-code"
              onClick={addNew}
              className="btn bg-red-800 text-white"
            >
              Add
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewVoucherCode;
