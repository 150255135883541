import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import {
  useAddSliderContentMutation,
  useDeleteContentMutation,
  useGetSliderContentsQuery,
} from "../../rtk/features/api/apiSlice";
import LoadingPage from "../Shared/LoadingPage";

const SliderContents = () => {
  const [user] = useAuthState(auth);
  const [link, setLink] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [deleteContent] = useDeleteContentMutation();
  const [addSliderContent] = useAddSliderContentMutation();
  const { data, isLoading } = useGetSliderContentsQuery();
  const { result: allContents } = data || {};
  if (isLoading) return <LoadingPage />;
  //   to add content
  const addNewContent = (e) => {
    e.preventDefault();
    // console.log(link, imgUrl);
    if (link !== "" && imgUrl !== "") {
      const data = {
        imgUrl: imgUrl,
        link: link,
        adminEmail: user?.email,
      };
      addSliderContent(data);
    }
  };
  //   to delete content
  const deleteSliderContent = (content) => {
    const details = {
      id: content._id,
      adminEmail: user?.email,
    };
    deleteContent(details);
  };
  return (
    <section className="pt-2 w-[90%] text-gray-900 mx-auto rounded-md">
      <div className="mt-10 md:mt-10  md:border-none">
        <p className="font-semibold py-1 px-4 rounded-full mb-3 text-primary text-2xl ">
          Slider Contents
        </p>
        <form className="pl-4 lg:w-[500px]">
          <div className="flex flex-col mb-5">
            <label
              htmlFor="name"
              className="text-[19px] font-semibold text-gray-900"
            >
              Image URL:
            </label>
            <input
              type="text"
              value={imgUrl}
              autoComplete="off"
              onChange={(e) => setImgUrl(e.target.value)}
              name="imgurl"
              placeholder="Enter Image URL Here"
              className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer"
            />
          </div>
          <div className="inline-block w-full">
            <label className="relative block w-full">
              <span className="text-[19px] font-semibold text-gray-900">
                Link:
              </span>
              <div className="relative inline-block w-full">
                <input
                  required
                  autoComplete="off"
                  value={link}
                  type="text"
                  onChange={(e) => setLink(e.target.value)}
                  name="link"
                  className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer"
                  placeholder="Enter the link here"
                />
              </div>
            </label>
          </div>
          <div className="flex justify-end my-2">
            <button
              onClick={(e) => addNewContent(e)}
              className="bg-blue-400 right-0 px-3 rounded-lg font-bold text-white py-1"
            >
              Add
            </button>
          </div>
        </form>
        <div className="p-2 mx-auto sm:p-4 ">
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white  text-xl">
              <thead className="">
                <tr className="text-left">
                  <th className="p-3 border">Index</th>
                  <th className="p-3 border">ImgUrl</th>
                  <th className="p-3 border">Link</th>
                  <th className="p-3 border">Action</th>
                </tr>
              </thead>
              <tbody>
                {allContents?.map((each, Index) => (
                  <tr key={each._id} className="border-b  border-opacity-20 ">
                    <td className="p-3 border">
                      <p>{Index + 1}</p>
                    </td>
                    <td className="p-3 border">
                      <p>{each.imgUrl}</p>
                    </td>
                    <td className="p-3 border">
                      <p>{each.link}</p>
                    </td>

                    <td className="p-3 border">
                      <p>
                        <label
                          htmlFor="delete"
                          onClick={() => deleteSliderContent(each)}
                          className="px-2 py-1 cursor-pointer rounded-full text-[15px] text-white bg-red-500"
                        >
                          Delete
                        </label>
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderContents;
