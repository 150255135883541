import React, { useState } from "react";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";

const AddNewRechargeOption = ({ setUpdate }) => {
  const [user] = useAuthState(auth);
  const [amount, setAmount] = useState("");
  const [productName, setProductName] = useState("");
  const [packageName, setPackageName] = useState("");
  //to update
  const regexForNumber = /^-?\d+\.?\d*$/;
  const addNew = (id) => {
    if (!regexForNumber.test(amount)) {
      return toast.error("Please enter valid number.");
    }
    if (regexForNumber.test(amount)) {
      let amountInNumber = parseInt(amount);
      const detailsOfPackage = {
        productName: productName,
        name: packageName,
        adminEmail: user?.email,
        amount: amountInNumber,
      };
      //   console.log(detailsOfPackage, typeof amountInNumber);
      //   posting
      fetch("https://backend.topuphero.com/api/admin/insert-recharge-package", {
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(detailsOfPackage),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.result) {
            setUpdate(data?.result);
            setAmount("");
            setPackageName("");
            toast.success("New Recharge Package is added.");
          } else {
            toast.error(data?.message);
          }
        });
    }
  };
  return (
    <div>
      <input
        type="checkbox"
        id="add-new-recharge-package"
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          {" "}
          <h1 className="font-semibold text-xl py-2">
            Add New Recharge Package
          </h1>
          <div>
            <h1 className="font-semibold text-xl">Choose the product</h1>
            <input
              onClick={(e) => setProductName(e.target.value)}
              type="radio"
              id="product-one"
              name="product"
              value="FREE FIRE INGAME"
            />
            <label htmlFor="product-one">FREE FIRE INGAME</label>
            <br /> 
            <input
              onClick={(e) => setProductName(e.target.value)}
              type="radio"
              id="product-two"
              name="product"
              value="FREE FIRE IDCODE"
            />
            <label htmlFor="product-two">FREE FIRE IDCODE</label>
            <br /> 
            <input
              onClick={(e) => setProductName(e.target.value)}
              type="radio"
              id="product-three"
              name="product"
              value="FREE FIRE PROMO"
            />
            <label htmlFor="product-three">FREE FIRE PROMO</label>
            <br /> 
            <input
              onClick={(e) => setProductName(e.target.value)}
              type="radio"
              id="product-four"
              name="product"
              value="PUBG MOBILE"
            />
            <label htmlFor="product-four">PUBG MOBILE</label>
            <br /> 
            <input
              onClick={(e) => setProductName(e.target.value)}
              type="radio"
              id="product-five"
              name="product"
              value="FREE FIRE INDONESIA"
            />
            <label htmlFor="product-five">FREE FIRE INDONESIA</label>
            <br /> 
            <input
              onClick={(e) => setProductName(e.target.value)}
              type="radio"
              id="product-six"
              name="product"
              value="LESS IS MORE"
            />
            <label htmlFor="product-six">LESS IS MORE</label>
            <br /> 
            <input
              onClick={(e) => setProductName(e.target.value)}
              type="radio"
              id="product-seven"
              name="product"
              value="UNIPIN VOUCHER"
            />
            <label htmlFor="product-seven">UNIPIN VOUCHER</label>
            <br /> 
          </div>
          <div className="flex flex-col mb-5">
            <label
              htmlFor="name"
              className="text-[19px] font-semibold text-gray-900"
            >
              Package Name:
            </label>
            <input
              type="text"
              value={packageName}
              autoComplete="off"
              onChange={(e) => setPackageName(e.target.value)}
              name="name"
              placeholder="Enter Package Name Here"
              className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer"
            />
          </div>
          <div className="inline-block w-full">
            <label className="relative block w-full">
              <span className="text-[19px] font-semibold text-gray-900">
                Amount
              </span>
              <div className="relative inline-block w-full">
                <input
                  required
                  autoComplete="off"
                  value={amount}
                  type="text"
                  onChange={(e) => setAmount(e.target.value)}
                  name="amount"
                  className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer"
                  placeholder="Enter new amount here"
                />
              </div>
            </label>
          </div>
          <div className="modal-action">
            <label
              htmlFor="add-new-recharge-package"
              className="btn bg-green-800 text-white"
            >
              No
            </label>
            <label
              htmlFor="add-new-recharge-package"
              onClick={addNew}
              className="btn bg-red-800 text-white"
            >
              Add
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewRechargeOption;
