import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyAxZ3RrbxvMQMzSdOF6qm2pX3ih6dw0LHM",
  authDomain: "easy-topup-bd.firebaseapp.com",
  projectId: "easy-topup-bd",
  storageBucket: "easy-topup-bd.appspot.com",
  messagingSenderId: "278560698816",
  appId: "1:278560698816:web:eb69207ee566f5f9a71825",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;
