import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "easytopupbd",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://backend.topuphero.com/api",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["mobile", "topup", "order", "delete", "add", "vouchers"],
  endpoints: (builder) => ({
    //
    getProfile: builder.query({
      query: (email) => `/my-profile/${email}`,
      keepUnusedDataFor: 500,
      providesTags: ["mobile", "order"],
    }),
    checkAdmin: builder.query({
      query: (email) => `/auth/admin/${email}`,
    }),
    getAllProducts: builder.query({
      query: () => `/all-products`,
      keepUnusedDataFor: 600,
    }),
    getProduct: builder.query({
      query: (id) => `/product/${id}`,
    }),
    getRechargePackage: builder.query({
      query: (productName) => `/all-recharge-options/${productName}`,
    }),
    getPaymentSystem: builder.query({
      query: () => `/all-payment-system`,
    }),
    getMyTopupOrders: builder.query({
      query: (data) =>
        `my-topup-orders?email=${data.email}&limit=${data.limit}`,
      providesTags: ["topup"],
    }),
    //
    getMyProductOrders: builder.query({
      query: (data) => `my-orders?email=${data.email}&limit=${data.limit}`,
      providesTags: ["order"],
    }),
    //
    // getVoucherCodesOfARechargePackages: builder.query({
    //   query: (data) =>
    //     `/all-voucher-codes/${data?.rechargeId}/${data?.adminEmail}/${data?.state}`,
    //   providesTags: ["vouchers"],
    // }),
    // to update mobile number
    updateMobile: builder.mutation({
      query: ({ email, data }) => ({
        url: `/update-phone/${email}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["mobile"],
    }),
    // to order
    orderProduct: builder.mutation({
      query: (data) => ({
        url: `/insert-product-order`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["order"],
    }),
    // topup order
    orderTopup: builder.mutation({
      query: (data) => ({
        url: `/add-topup-order`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["topup"],
    }),
    // slider related api
    addSliderContent: builder.mutation({
      query: (data) => ({
        url: `/admin/insert-slider-content`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["add"],
    }),
    getSliderContents: builder.query({
      query: () => `/all-slider-contents`,
      keepUnusedDataFor: 600,
      providesTags: ["delete", "add"],
    }),
    deleteContent: builder.mutation({
      query: (data) => ({
        url: `admin/delete-slider-content?id=${data.id}&adminEmail=${data.adminEmail}`,
        method: "DELETE",
      }),
      invalidatesTags: ["delete"],
    }),
  }),
});
export const {
  useGetAllProductsQuery,
  useCheckAdminQuery,
  useGetMyTopupOrdersQuery,
  useGetMyProductOrdersQuery,
  useGetPaymentSystemQuery,
  useGetProductQuery,
  useUpdateMobileMutation,
  useGetProfileQuery,
  useGetRechargePackageQuery,
  useOrderTopupMutation,
  useOrderProductMutation,
  // useGetVoucherCodesOfARechargePackagesQuery,
  useAddSliderContentMutation,
  useGetSliderContentsQuery,
  useDeleteContentMutation,
} = apiSlice;
