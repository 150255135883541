import React from "react";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
const AddTopupBalance = ({ order, setUpdate }) => {
  const [user] = useAuthState(auth);
  const { _id, amount } = order;
  //to update
  const updateStatus = (id) => {
    const updatedDetails = {
      balance: amount,
      topupID: id,
      adminEmail: user?.email,
    };
    // posting
    fetch("https://backend.topuphero.com/api/admin/insert-balance", {
      method: "PATCH",
      headers: {
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "content-type": "application/json",
      },
      body: JSON.stringify(updatedDetails),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result) {
          setUpdate(data.result);
          toast.success("Balance Inserted");
        } else {
          toast.error("Try again later.");
        }
      });
  };
  return (
    <div>
      <input type="checkbox" id="insert-balance-now" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            Everything is ok. So, you want to update this topup request. right?
          </h3>
          <div className="modal-action">
            <label
              htmlFor="insert-balance-now"
              className="btn bg-green-800 text-white"
            >
              No
            </label>
            <label
              htmlFor="insert-balance-now"
              onClick={() => updateStatus(_id)}
              className="btn bg-red-800 text-white"
            >
              Yes
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTopupBalance;
