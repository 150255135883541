import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import LoadingPage from "./LoadingPage";
import { useGetSliderContentsQuery } from "../../rtk/features/api/apiSlice";
const Slider = () => {
  const { data, isLoading } = useGetSliderContentsQuery();
  const { result: allContents } = data || {};
  if (isLoading) return <LoadingPage />;

  return (
    <section className="mb-6 md:my-8 bg-white w-[88%]">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={1}
        navigation
        autoHeight={false}
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
      >
        {allContents?.map((content) => (
          <SwiperSlide key={content._id}>
            <a href={content.link}>
              <img loading="lazy" src={content.imgUrl} alt="" />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Slider;
