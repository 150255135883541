import React from "react";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";

const ConfirmAdmin = ({ user, setModal }) => {
  const [userFromRFH] = useAuthState(auth);
  const { _id, isAdmin } = user;
  //to delete
  const makeAdmin = (id) => {
    fetch(
      `https://backend.topuphero.com/api/admin/make-admin?id=${id}&adminEmail=${userFromRFH?.email}`,
      {
        method: "PATCH",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.result) {
          setModal(null);
          toast.success(`The users's status is updated.`);
        }
      });
  };
  return (
    <div>
      <input type="checkbox" id="make-admin" className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            Are you sure you want to{" "}
            {isAdmin
              ? "remove this user from admin role"
              : "make this user as admin"}
            ?
          </h3>
          <div className="modal-action">
            <label htmlFor="make-admin" className="btn bg-green-800 text-white">
              No
            </label>
            <label
              for="make-admin"
              onClick={() => makeAdmin(_id)}
              className="btn text-white bg-red-800"
            >
              Yes
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAdmin;
