import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";
import auth from "../../firebase.init";

const UpdateOrderStatus = ({ order, setUpdate }) => {
  const [user] = useAuthState(auth);
  const [status, setStatus] = useState("");
  const { _id, money, requestEmail } = order;
  //to update
  const updateStatus = (id) => {
    if (status === "") {
      return toast.error("Select the problem first.");
    }
    const updatedDetails = {
      status: status,
      orderID: id,
      balance: money,
      requestEmail: requestEmail,
      adminEmail: user?.email,
    };
    // posting
    fetch("https://backend.topuphero.com/api/admin/update-order", {
      method: "PATCH",
      headers: {
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "content-type": "application/json",
      },
      body: JSON.stringify(updatedDetails),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result) {
          setUpdate(id);
          toast.success("Status is updated.");
        } else {
          toast.error("Try again later.");
        }
      });
  };
  return (
    <div>
      <input
        type="checkbox"
        id="update-status-of-product-order"
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <div className="">
            <p>Please select the problem:</p> {" "}
            <input
              onClick={(e) => setStatus(e.target.value)}
              type="radio"
              id="reason-one"
              name="update-status"
              value="প্লেয়ার আইডি সঠিক ছিল না । সঠিক প্লেয়ার আইডি দিয়ে আবার অর্ডার করুন।"
            />
            <label htmlFor="reason-one">
              প্লেয়ার আইডি কোড সঠিক ছিল না । সঠিক প্লেয়ার আইডি কোড দিয়ে আবার
              অর্ডার করুন।
            </label>
            <br />  {/*  */}
            <input
              onClick={(e) => setStatus(e.target.value)}
              type="radio"
              id="reason-two"
              name="update-status"
              value="জিমেইল সঠিক ছিল না । সঠিক জিমেইল দিয়ে
              আবার অর্ডার করুন।"
            />
            <label htmlFor="reason-two">
              জিমেইল সঠিক ছিল না । সঠিক জিমেইল দিয়ে আবার অর্ডার করুন।
            </label>
            <br />
            {/*  */}
            <input
              onClick={(e) => setStatus(e.target.value)}
              type="radio"
              id="reason-three"
              name="update-status"
              value="পাসওয়ার্ড সঠিক ছিল না । সঠিক পাসওয়ার্ড দিয়ে
              আবার অর্ডার করুন।"
            />
            <label htmlFor="reason-three">
              পাসওয়ার্ড সঠিক ছিল না । সঠিক পাসওয়ার্ড দিয়ে আবার অর্ডার করুন।
            </label>
            <br />
            {/*  */}
            <input
              onClick={(e) => setStatus(e.target.value)}
              type="radio"
              id="reason-nine"
              name="update-status"
              value="পাসওয়ার্ড পরিবর্তন করে, ব্যাকআপ কোড দিয়ে অর্ডার করুন।"
            />
            <label htmlFor="reason-nine">
              পাসওয়ার্ড পরিবর্তন করে, ব্যাকআপ কোড দিয়ে অর্ডার করুন।
            </label>
            <br />
            {/*  */}
            <input
              onClick={(e) => setStatus(e.target.value)}
              type="radio"
              id="reason-four"
              name="update-status"
              value="ব্যাকআপ কোড সঠিক ছিল না । সঠিক ব্যাকআপ কোড দিয়ে
              আবার অর্ডার করুন।"
            />
            <label htmlFor="reason-four">
              ব্যাকআপ কোড সঠিক ছিল না । সঠিক ব্যাকআপ কোড দিয়ে আবার অর্ডার করুন।
            </label>
            <br />
            {/*  */}
            <input
              onClick={(e) => setStatus(e.target.value)}
              type="radio"
              id="reason-five"
              name="update-status"
              value=" আপনার এয়ারড্রপ এর দাম $1.99"
            />
            <label htmlFor="reason-five">আপনার এয়ারড্রপ এর দাম $1.99</label>
            <br />
            {/*  */}
            <input
              onClick={(e) => setStatus(e.target.value)}
              type="radio"
              id="reason-six"
              name="update-status"
              value="আপনার এই ড্রপটি হবেনা। এইটার দাম বেশি ।"
            />
            <label htmlFor="reason-six">
              আপনার এই ড্রপটি হবেনা। এইটার দাম বেশি ।
            </label>
            <br />
            {/*  */}
            <input
              onClick={(e) => setStatus(e.target.value)}
              type="radio"
              id="reason-seven"
              name="update-status"
              value="শুধুমাত্র বাংলাদেশ সার্ভারে আইডি কোড দিয়ে টপআপ হবে।"
            />
            <label htmlFor="reason-seven">
              শুধুমাত্র বাংলাদেশ সার্ভারে আইডি কোড দিয়ে টপআপ হবে।
            </label>
            <br />
            {/*  */}
            <input
              onClick={(e) => setStatus(e.target.value)}
              type="radio"
              id="reason-eight"
              name="update-status"
              value="আপনার অ্যাকাউন্টে নাই।"
            />
            <label htmlFor="reason-eight">আপনার অ্যাকাউন্টে নাই।</label>
            <br />
            {/*  */}
            <p className="pl-2">
              <input
                onClick={(e) => setStatus(e.target.value)}
                className=""
                type="radio"
                id="paid"
                name="update-status"
                value="paid"
              />
              <label className="text-green-400" htmlFor="paid">
                পরিশোধ করা হয়েছে
              </label>
            </p>
          </div>
          <div className="modal-action">
            <label
              htmlFor="update-status-of-product-order"
              className="btn bg-green-800 text-white"
            >
              No
            </label>
            <label
              htmlFor="update-status-of-product-order"
              onClick={() => updateStatus(_id)}
              className="btn bg-red-800 text-white"
            >
              Update
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateOrderStatus;
