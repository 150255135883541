import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  useSignInWithEmailAndPassword,
  useSignInWithGoogle,
} from "react-firebase-hooks/auth";
import { toast } from "react-toastify";
import LoadingPage from "../Shared/LoadingPage";
import useToken from "../../Hooks/useToken";
import auth from "../../firebase.init";

function Login() {
  const [signInWithGoogle, userOfGoog, loadingOfGoog, errorOfGoog] =
    useSignInWithGoogle(auth);
  //to login by email and password
  const [
    signInWithEmailAndPassword,
    userOfEmail,
    loadingOfEmail,
    errorOfEmail,
  ] = useSignInWithEmailAndPassword(auth);
  //to navigate the user
  const navigate = useNavigate();
  // react form
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();
  // to create user by google
  //to sign in by google
  // to generate token
  const [token] = useToken(userOfEmail || userOfGoog);
  const formSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    if (email && password) {
      signInWithEmailAndPassword(email, password);
    }
  };
  useEffect(() => {
    if (userOfEmail || userOfGoog) {
      navigate("/");
    }
    if (errorOfGoog) {
      toast.error(errorOfGoog?.message);
    }
    if (errorOfEmail?.message === "Firebase: Error (auth/wrong-password).") {
      toast.error("Wrong Password.");
    }
    if (errorOfEmail?.message === "Firebase: Error (auth/user-not-found).") {
      toast.error("User not found.");
    }
  }, [navigate, userOfEmail, userOfGoog, errorOfEmail?.message, errorOfGoog]);

  if (loadingOfEmail || loadingOfGoog) {
    return <LoadingPage></LoadingPage>;
  }
  return (
    <section className="flex justify-center w-[100%] items-center my-5">
      <div className="flex flex-col border md:w-[550px] lg:w-[600px] w-[80%] px-4 py-8 bg-white rounded-lg shado sm:px-6 md:px-8 lg:px-10">
        <div className="mb-6 text-gray-600">
          <h1 className="text-xl md:text-2xl font-bold">Welcome Back</h1>
          <p className="text-sm">
            Enter your credentials to access your account.
          </p>
        </div>
        {/* Google Button */}
        <div className="my-6 space-y-4 ">
          <button
            onClick={() => signInWithGoogle()}
            aria-label="Login with Google"
            type="button"
            className="flex items-center justify-center w-full p-4 space-x-4 border rounded-md border-primary text-gray-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              className="w-5 h-5 fill-current"
            >
              <path d="M16.318 13.714v5.484h9.078c-0.37 2.354-2.745 6.901-9.078 6.901-5.458 0-9.917-4.521-9.917-10.099s4.458-10.099 9.917-10.099c3.109 0 5.193 1.318 6.38 2.464l4.339-4.182c-2.786-2.599-6.396-4.182-10.719-4.182-8.844 0-16 7.151-16 16s7.156 16 16 16c9.234 0 15.365-6.49 15.365-15.635 0-1.052-0.115-1.854-0.255-2.651z"></path>
            </svg>
            <p>Login with Google</p>
          </button>
        </div>
        <div className="mt-8">
          <form onSubmit={formSubmit}>
            {/* Email */}
            <label className="text-gray-500" htmlFor="email">
              Email Address
            </label>
            <div className="flex relative ">
              <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                <svg
                  width="15"
                  height="15"
                  fill="currentColor"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                </svg>
              </span>
              <input
                type="text"
                autoComplete="off"
                required
                className={`rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-primary focus:ring-0 focus:border-transparent`}
                {...register("email", {
                  required: "Email is Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                onKeyUp={() => {
                  trigger("email");
                }}
              />{" "}
            </div>
            <label htmlFor="email">
              {" "}
              {errors.email && (
                <small className="text-primary">
                  🚫 {errors?.email?.message}
                </small>
              )}
            </label>
            <br />
            {/* Password */}
            <label className="text-gray-500" htmlFor="password">
              Password
            </label>
            <div className="flex relative ">
              <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                <svg
                  width="15"
                  height="15"
                  fill="currentColor"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z"></path>
                </svg>
              </span>
              <input
                autoComplete="off"
                type="password"
                required
                className={`rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-primary focus:ring-0 focus:border-transparent`}
                {...register("password", {
                  required: "Password is Required",
                  pattern: {
                    value: "",
                    message: "",
                  },
                })}
                onKeyUp={() => {
                  trigger("password");
                }}
              />
            </div>
            <label htmlFor="password">
              {" "}
              {errors.password && (
                <small className="text-primary">
                  🚫 {errors?.password?.message}
                </small>
              )}
            </label>
            <br />
            <div className="flex items-center flex-col md:flex-row justify-between">
              <label className="flex items-center font-bold my-4">
                <input
                  type="checkbox"
                  className="leading-loose bg-primary focus:text-primary"
                />
                <span className="py-2 ml-1 text-gray-500 leading-snug">
                  Remember Me
                </span>
              </label>
              {/* forgot password */}
              <span>
                <Link
                  to="/reset-password"
                  className="cursor-pointer text-primary   hover:border-b-secondary hover:border-b"
                >
                  <span>Forgot Password?</span>
                </Link>
              </span>
            </div>
            <input
              type="submit"
              className="btn btn-primary uppercase font-bold w-full mt-2 text-white"
              value="Login"
              id=""
            />{" "}
            <br />
          </form>
        </div>
        <div className="flex items-center justify-center mt-6">
          <span className="ml-2 text-gray-800">Don't have any account?</span>{" "}
          <span className="ml-1 text-primary">
            <Link to="/register">Register</Link>
          </span>
        </div>
      </div>
    </section>
  );
}

export default Login;
