import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Components/Authentication/Login";
import Register from "./Components/Authentication/Register";
import RequireAuth from "./Components/Authentication/RequireAuth";
import RequireAdmin from "./Components/Authentication/RequireAdmin";
import HomePage from "./Components/HomePage";
import Footer from "./Components/Shared/Footer";
import Navbar from "./Components/Shared/Navbar";
import NotFound from "./Components/Shared/NotFound";
import MyOrder from "./Components/User/MyOrder";
import MyProfile from "./Components/User/MyProfile";
import MyWallet from "./Components/User/MyWallet";
import AddBalance from "./Components/User/AddBalance";
import ResetPassword from "./Components/Authentication/ResetPassword";
import AllUsers from "./Components/Admin/AllUsers";
import AllTopupOrders from "./Components/Admin/AllTopupOrders";
import AllProducts from "./Components/Admin/AllProducts";
import AddNewProducts from "./Components/Admin/AddNewProducts";
import InsertTopup from "./Components/Admin/InsertTopup";
import BuyProduct from "./Components/Shared/BuyProduct";
import { useEffect } from "react";
import AllRechargeOptions from "./Components/Admin/AllRechargeOptions";
import AllOrders from "./Components/Admin/AllOrders";
import SliderContents from "./Components/Admin/SliderContents";
import VoucherCodeDetails from "./Components/Admin/VoucherCodeDetails";

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <main className="">
      {/* Navbar */}
      <Navbar />
      <div className="mt-[90px]">
        <Routes>
          {/* Home */}
          <Route path="/" element={<HomePage />}></Route>
          {/* Login */}
          <Route path="/login" element={<Login />}></Route>
          {/* Register */}
          <Route path="/register" element={<Register />}></Route>
          {/* User profile */}
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <MyProfile />
              </RequireAuth>
            }
          ></Route>
          {/* User wallet */}
          <Route
            path="/wallet"
            element={
              <RequireAuth>
                <MyWallet />
              </RequireAuth>
            }
          ></Route>
          {/* User orders */}
          <Route
            path="/order"
            element={
              <RequireAuth>
                <MyOrder />
              </RequireAuth>
            }
          ></Route>
          {/* Buy game */}
          <Route path="/product/:id" element={<BuyProduct />}></Route>
          {/* Buy balance: Nested Routes */}
          <Route
            path="/add-money"
            element={
              <RequireAuth>
                <AddBalance />
              </RequireAuth>
            }
          ></Route>
          <Route path="/reset-password" element={<ResetPassword />}></Route>

          {/**********************
           Routes for admin only 
          ***********************/}
          <Route
            path="/all-product-order"
            element={
              <RequireAuth>
                <RequireAdmin>
                  <AllOrders />
                </RequireAdmin>
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/all-users"
            element={
              <RequireAuth>
                <RequireAdmin>
                  <AllUsers />
                </RequireAdmin>
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/all-topup-orders"
            element={
              <RequireAuth>
                <RequireAdmin>
                  <AllTopupOrders />
                </RequireAdmin>
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/all-products"
            element={
              <RequireAuth>
                <RequireAdmin>
                  <AllProducts />
                </RequireAdmin>
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/add-product"
            element={
              <RequireAuth>
                <RequireAdmin>
                  <AddNewProducts />
                </RequireAdmin>
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/insert-balance"
            element={
              <RequireAuth>
                <RequireAdmin>
                  <InsertTopup />
                </RequireAdmin>
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/all-recharge-options"
            element={
              <RequireAuth>
                <RequireAdmin>
                  <AllRechargeOptions />
                </RequireAdmin>
              </RequireAuth>
            }
          ></Route>
          {/* Voucher Code */}
          <Route
            path="/voucher-code"
            element={
              <RequireAuth>
                <RequireAdmin>
                  <VoucherCodeDetails />
                </RequireAdmin>
              </RequireAuth>
            }
          ></Route>
          {/* sliderContents */}
          <Route
            path="/slider-contents"
            element={
              <RequireAuth>
                <RequireAdmin>
                  <SliderContents />
                </RequireAdmin>
              </RequireAuth>
            }
          ></Route>

          {/* Notfound */}
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
        <Footer />
      </div>
      <ToastContainer />
    </main>
  );
}

export default App;
