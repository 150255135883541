import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import auth from "../../firebase.init";
import {
  useGetMyTopupOrdersQuery,
  useGetProfileQuery,
} from "../../rtk/features/api/apiSlice";
import LoadingPage from "../Shared/LoadingPage";

function MyWallet() {
  const [user] = useAuthState(auth);
  const [limit, setLimit] = useState(10);
  const { data, isLoading } = useGetProfileQuery(user?.email);
  const { result: profile } = data || {};
  const { data: myTopupOrders, isLoading: loading } = useGetMyTopupOrdersQuery({
    email: user?.email,
    limit: limit,
  });
  const { result: topupOrders } = myTopupOrders || {};
  if (isLoading) return <LoadingPage />;
  // to get topup orders from db
  if (loading || isLoading) return <LoadingPage />;
  return (
    <section className="flex justify-center">
      <div className="py-5 md:py-7 text-gray-900 w-[90%] bg-gray-50">
        <div className="">
          <div className="bg-white border border-gray-200 rounded-md overflow-hidden md:w-[600px] text-center md:mx-auto px-4 md:px-5 py-6">
            <p className="_h5 mb-1 text-primary-500 !font-bold">
              ৳ {profile?.balance}
            </p>
            <p className="_subtitle1 !text-sm">Available Balance</p>
            <div className="md:w-[70%] md:mx-auto mt-5">
              <Link to={`/add-money`}>
                <button className="bg-primary text-white primary px-3 py-2 text-[14px]  text-sm rounded-md duration-200 ease-in-out font-medium border outline-none no-underline select-none disabled:opacity-50 disabled:pointer-events-none active:opacity-60 w-full block  relative">
                  <span className="flex items-center h-full w-full justify-center gap-1.5 ">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      color="#fffff"
                      height="20"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="m15 12 5-4-5-4v2.999H2v2h13zm7 3H9v-3l-5 4 5 4v-3h13z"></path>
                    </svg>
                    Topup Account Balance
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* Second Section: Transaction details */}
        <div className="mt-8 rounded-md">
          <section className="mt-7 md:mt-0 border-t border-gray-200 md:border-none">
            <div className="!px-0 md:!px-5 md:my-7">
              <div className="bg-white">
                <div className="md:border border-gray-200 md:rounded-md pt-4 relative">
                  <p className="_subtitle2 font-semibold py-1 px-4 rounded-full mb-3 text-gray-800 text-lg">
                    My Transactions
                  </p>
                  {topupOrders?.length === 0 ? (
                    <div className="overflow-auto">
                      <div className="flex justify-center items-center py-7 flex-col gap-3 ">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 24 24"
                          height="55"
                          width="55"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path>
                          <path d="M11.412 8.586c.379.38.588.882.588 1.414h2a3.977 3.977 0 0 0-1.174-2.828c-1.514-1.512-4.139-1.512-5.652 0l1.412 1.416c.76-.758 2.07-.756 2.826-.002z"></path>
                        </svg>
                        <div className="text-center">
                          <h3 className="_h3 font-extrabold mb-1.5">Sorry</h3>
                          <p className="_body2">We found nothing for you.</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="p-2 mx-auto sm:p-4 ">
                      <div className="overflow-x-auto">
                        <table className="min-w-full bg-white  text-xl">
                          <thead className="">
                            <tr className="text-left">
                              <th className="p-3 border">Index</th>
                              <th className="p-3 border">Date</th>
                              <th className="p-3 border">Amount</th>
                              <th className="p-3 border">TransId</th>
                              <th className="p-3 border">Status</th>
                              <th className="p-3 border">Message</th>
                            </tr>
                          </thead>
                          <tbody>
                            {topupOrders?.map((each, Index) => (
                              <tr
                                key={each._id}
                                className="border-b  border-opacity-20 "
                              >
                                <td className="p-3 border">
                                  <p>{Index + 1}</p>
                                </td>
                                <td className="p-3 border">
                                  <p>{each.date}</p>
                                </td>
                                <td className="p-3 border">
                                  <p>{each.amount}</p>
                                </td>
                                <td className="p-3 border">
                                  <p>{each.transactionID}</p>
                                </td>
                                <td className="p-3 border">
                                  <p
                                    className={`rounded-lg px-1 ${
                                      each.statusThree === "Paid"
                                        ? "bg-green-400"
                                        : each.statusTwo === "Pending"
                                        ? "bg-red-400"
                                        : "bg-red-700"
                                    }  `}
                                  >
                                    {each.statusThree}{" "}
                                  </p>
                                </td>
                                <td className="p-3 border">
                                  <p>{each.statusTwo}</p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>{" "}
        {topupOrders?.length >= 10 && (
          <div className="w-full flex items-end justify-end">
            <button
              onClick={() => setLimit(limit + 10)}
              className="bg-primary px-2 py-1 text-white rounded-lg mr-6 sm:mr-8 md:mr-14 lg:mr-20 mb-5"
            >
              See More
            </button>
          </div>
        )}
      </div>
    </section>
  );
}

export default MyWallet;
