import React from "react";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
const ConfirmDelete = ({ product, allProduct, setAllProductsl }) => {
  const [user] = useAuthState(auth);
  const { _id, name } = product;
  //to delete
  const handleDelete = (id) => {
    fetch(
      `https://backend.topuphero.com/api/admin/delete-product?id=${id}&adminEmail=${user?.email}`,
      {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.result) {
          const remain = allProduct.filter((x) => id !== x._id);
          toast.success(`Your product ${name} is deleted.`);
          setAllProductsl(remain);
        }
      });
  };
  return (
    <div>
      <input
        type="checkbox"
        id="delete-product-form-modal"
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            Are you sure you want to delete this product?
          </h3>
          <div className="modal-action">
            <label
              htmlFor="delete-product-form-modal"
              className="btn bg-green-800 text-white"
            >
              No
            </label>
            <label
              htmlFor="delete-product-form-modal"
              onClick={() => handleDelete(_id)}
              className="btn bg-red-800 text-white"
            >
              Yes
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDelete;
