import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";
import auth from "../../firebase.init";

const UpdateAmountOfRecharge = ({ order, setUpdate }) => {
  const [user] = useAuthState(auth);
  const [amount, setAmount] = useState("");
  const { _id } = order;
  //to update
  const regexForNumber = /^-?\d+\.?\d*$/;
  const updateamount = (id) => {
    if (!regexForNumber.test(amount)) {
      return toast.error("Please enter valid number.");
    }
    if (regexForNumber.test(amount)) {
      let amountInNumber = parseInt(amount);
      const updatedDetails = {
        amount: amountInNumber,
        orderID: id,
        adminEmail: user?.email,
      };
      //   console.log(updatedDetails);
      //   posting
      fetch("https://backend.topuphero.com/api/admin/update-recharge-option", {
        method: "PATCH",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(updatedDetails),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.result) {
            setUpdate(id);
            setAmount("");
            toast.success("Balance is updated.");
          } else {
            toast.error(data?.message);
          }
        });
    }
  };
  return (
    <div>
      <input
        type="checkbox"
        id="update-amount-of-recharge-options"
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          {" "}
          <h1 className="font-semibold text-xl py-2">
            You are going to update the amount of{" "}
            <span className="text-primary">
              {order.productName} {order.name}
            </span>{" "}
            Package which was{" "}
            <span className="text-green-500">{order.amount}</span> taka.
          </h1>
          <div className="inline-block w-full">
            <label className="relative block w-full">
              <span className="text-[14px]  text-slate-500  font-semibold mb-1 inline-block select-none ">
                New Amount
              </span>
              <div className="relative inline-block w-full">
                <input
                  required
                  autoComplete="off"
                  value={amount}
                  type="text"
                  onChange={(e) => setAmount(e.target.value)}
                  name="amount"
                  className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer"
                  placeholder="Enter new amount here"
                />
              </div>
            </label>
          </div>
          <div className="modal-action">
            <label
              htmlFor="update-amount-of-recharge-options"
              className="btn bg-green-800 text-white"
            >
              No
            </label>
            <label
              htmlFor="update-amount-of-recharge-options"
              onClick={() => updateamount(_id)}
              className="btn bg-red-800 text-white"
            >
              Update
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAmountOfRecharge;
