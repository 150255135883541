import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";
import auth from "../../firebase.init";

const UpdateTopupOrderStatus = ({ order, setUpdate }) => {
  const [user] = useAuthState(auth);
  const [status, setStatus] = useState("");
  const { _id, transactionID, email } = order;
  //to update
  const updateStatus = (id) => {
    if (status === "") {
      return toast.error("Select the problem first.");
    }
    const updatedDetails = {
      status: status,
      orderID: id,
      transactionID: transactionID,
      requestEmail: email,
      adminEmail: user?.email,
    };
    // posting
    fetch("https://backend.topuphero.com/api/admin/update-topup-order-status", {
      method: "PATCH",
      headers: {
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "content-type": "application/json",
      },
      body: JSON.stringify(updatedDetails),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.result) {
          setUpdate(id);
          toast.success("Status is updated.");
        } else {
          toast.error("Try again later.");
        }
      });
  };
  return (
    <div>
      <input
        type="checkbox"
        id="update-status-of-topup-order"
        className="modal-toggle"
      />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <div className="">
            {" "}
            <p>Please select the problem:</p> {" "}
            <input
              onClick={(e) => setStatus(e.target.value)}
              type="radio"
              id="reason-one"
              name="update-status"
              value="আপনি ভুল তথ্য দিয়েছেন। সঠিক তথ্য দিয়ে আবার ওয়ালেট ব্যাল্যান্স টপআপ অর্ডার করুন।"
            />
            <label htmlFor="reason-one">
              আপনি ভুল তথ্য দিয়েছেন। সঠিক তথ্য দিয়ে আবার ওয়ালেট ব্যাল্যান্স
              টপআপ অর্ডার করুন।
            </label>
            <br />
            <p className="pl-2">
              <input
                onClick={(e) => setStatus(e.target.value)}
                className=""
                type="radio"
                id="paid"
                name="update-status"
                value="paid"
              />
              <label className="text-green-400" htmlFor="paid">
                পরিশোধ করা হয়েছে
              </label>
            </p>
          </div>
          <div className="modal-action">
            <label
              htmlFor="update-status-of-topup-order"
              className="btn bg-green-800 text-white"
            >
              No
            </label>
            <label
              htmlFor="update-status-of-topup-order"
              onClick={() => updateStatus(_id)}
              className="btn bg-red-800 text-white"
            >
              Update
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateTopupOrderStatus;
