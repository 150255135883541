import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useCreateUserWithEmailAndPassword,
  useUpdateProfile,
} from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import LoadingPage from "../Shared/LoadingPage";
import { toast } from "react-toastify";
import { useState } from "react";
import useToken from "../../Hooks/useToken";
function Register() {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  //to navigate the user
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  //to create account by email and password
  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth, { sendEmailVerification: true });
  // to get token by registering user
  const [token] = useToken(user, name, mobile);
  //to update name
  const [updateProfile, updating] = useUpdateProfile(auth);
  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (data) {
      const nameOfUser = data.name;
      const email = data.email;
      const password = data.password;
      const confPassword = data.confPassword;
      if (password !== confPassword) {
        toast.error("Password doesn't matched.");
      }
      if (name && email && password === confPassword) {
        await createUserWithEmailAndPassword(email, password);
        await updateProfile({ displayName: nameOfUser });
      }
    }
  };
  useEffect(() => {
    if (error) {
      toast.error(error?.message);
    }
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user, from, navigate, error]);
  if (loading || updating) {
    return <LoadingPage></LoadingPage>;
  }
  return (
    <section className="flex justify-center w-[100%] items-center my-5">
      <div className="flex flex-col border md:w-[550px] lg:w-[600px] w-[80%] px-4 py-8 bg-white rounded-lg shado sm:px-6 md:px-8 lg:px-10">
        <div className="mb-6 text-gray-600">
          <h1 className="text-xl md:text-2xl font-bold">Create new account</h1>
        </div>

        <div className="mt-8">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Personal Details */}
            <div className="flex  flex-col md:flex-row gap-x-4 md:h-24">
              <div className="md:w-[50%] h-[90px] ">
                <label className="text-gray-500" htmlFor="name">
                  Your Name
                </label>
                <div className="flex relative ">
                  <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                    <svg
                      width="15"
                      height="35"
                      fill="currentColor"
                      viewBox="0 0 1024 1024"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M858.5 763.6a374 374 0 0 0-80.6-119.5 375.63 375.63 0 0 0-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 0 0-80.6 119.5A371.7 371.7 0 0 0 136 901.8a8 8 0 0 0 8 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 0 0 8-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    required
                    autoComplete="off"
                    className={`rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-primary focus:ring-0 focus:border-transparent`}
                    {...register("name", {
                      required: "Name is Required",
                      pattern: {
                        message: "",
                      },
                    })}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <label htmlFor="name">
                  {" "}
                  {errors.name && (
                    <small className="text-primary">
                      🚫 {errors?.name.message}
                    </small>
                  )}
                </label>
              </div>
              {/* Mobile Number */}
              <div className="md:w-[50%] h-[90px] md:h-0">
                <label className="text-gray-500" htmlFor="mobile">
                  Your Mobile
                </label>
                <div className="flex relative ">
                  <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                    <svg
                      width="15"
                      height="15"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    autoComplete="off"
                    required
                    className={`rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-primary focus:ring-0 focus:border-transparent`}
                    {...register("mobile", {
                      required: "Mobile Number is Required",
                      pattern: {
                        value:
                          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/i,
                        message: "Invalid Mobile Number",
                      },
                    })}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
                <label htmlFor="mobile">
                  {" "}
                  {errors.mobile && (
                    <small className="text-primary">
                      🚫 {errors?.mobile.message}
                    </small>
                  )}
                </label>
              </div>
            </div>
            {/* Email Address */}
            <div className="h-[90px]">
              <label className="text-gray-500" htmlFor="email">
                Email Address
              </label>
              <div className="flex relative ">
                <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                  <svg
                    width="15"
                    height="15"
                    fill="currentColor"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                  </svg>
                </span>
                <input
                  type="text"
                  autoComplete="off"
                  required
                  className={`rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-primary focus:ring-0 focus:border-transparent`}
                  {...register("email", {
                    required: "Email is Required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  onKeyUp={() => {
                    trigger("email");
                  }}
                />{" "}
              </div>
              <label htmlFor="email">
                {" "}
                {errors.email && (
                  <small className="text-primary">
                    🚫 {errors?.email.message}
                  </small>
                )}
              </label>
            </div>
            {/* Password */}
            <div className="flex flex-col md:flex-row  gap-x-4 md:h-28">
              <div className="md:w-[50%] h-[90px]">
                <label className="text-gray-500" htmlFor="password">
                  Password
                </label>
                <div className="flex relative ">
                  <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                    <svg
                      width="15"
                      height="15"
                      fill="currentColor"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z"></path>
                    </svg>
                  </span>
                  <input
                    type="password"
                    autoComplete="off"
                    required
                    className={`rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-primary focus:ring-0 focus:border-transparent`}
                    {...register("password", {
                      required: "Password is Required",
                      pattern: {
                        value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i,
                        message:
                          "Minimum 8 characters, at least 1 letter & 1 number",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("password");
                    }}
                  />
                </div>
                <label htmlFor="password">
                  {" "}
                  {errors.password && (
                    <small className="text-primary">
                      🚫 {errors?.password.message}
                    </small>
                  )}
                </label>
              </div>
              {/* Confirm Password */}
              <div className="md:w-[50%] h-[90px]">
                <label className="text-gray-500" htmlFor="password">
                  Confirm Password
                </label>
                <div className="flex relative ">
                  <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                    <svg
                      width="15"
                      height="15"
                      fill="currentColor"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z"></path>
                    </svg>
                  </span>
                  <input
                    type="password"
                    autoComplete="off"
                    required
                    className={`rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-primary focus:ring-0 focus:border-transparent`}
                    {...register("confPassword", {
                      required: "Confirm Password is Required",
                      pattern: {
                        value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i,
                        message:
                          "Minimum 8 characters, at least 1 letter & 1 number",
                      },
                    })}
                    onKeyUp={() => {
                      trigger("confPassword");
                    }}
                  />
                </div>
                <label htmlFor="confPassword">
                  {" "}
                  {errors.confPassword && (
                    <small className="text-primary">
                      🚫 {errors?.confPassword.message}
                    </small>
                  )}
                </label>
              </div>
            </div>{" "}
            <input
              type="submit"
              className="btn btn-primary uppercase font-bold w-full mt-2 text-white"
              value="Register"
              id=""
            />{" "}
            <br />
          </form>
        </div>
        <div className="flex items-center justify-center mt-6">
          <span className="ml-2 text-gray-900">Already have an account?</span>{" "}
          <span className="ml-1 text-primary">
            <Link to="/login">Login</Link>
          </span>
        </div>
      </div>
    </section>
  );
}

export default Register;
