import React from "react";
import { Link } from "react-router-dom";

function Product({ product }) {
  const { img, name, _id } = product;
  return (
    <Link to={`/product/${_id}`} className="relative w-full cursor-pointer">
      <div className="flex items-center justify-center bg-black h-[100px] md:h-[130px] lg:h-[160px] rounded-md relative">
        <img
          loading="lazy"
          className="w-[80%] absolute h-[100px] md:h-[125px] lg:h-[150px] top-[-25px]"
          src={img}
          alt="Product img"
        />
        <p className="absolute bottom-1 w-full font-bold text-[10px] md:text-[12px] lg:text-[15px] text-white text-center">
          {name}
        </p>
      </div>
    </Link>
  );
}

export default Product;
