import React, { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import LoadingPage from "../Shared/LoadingPage";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";

function AddNewProducts() {
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState("Yes");
  const imageUrlRef = useRef();
  const nameRef = useRef();
  if (loading) {
    return <LoadingPage />;
  }
  //   to add new product
  let activeStatus = active;
  if (active === "Yes") {
    activeStatus = true;
  }
  if (active === "No") {
    activeStatus = false;
  }
  const addNewProduct = (e) => {
    e.preventDefault();
    const name = nameRef.current.value;
    const imageUrl = imageUrlRef.current.value;
    if (name && imageUrl && active) {
      const product = {
        name: name,
        img: imageUrl,
        adminEmail: user?.email,
        active: activeStatus,
      };
      setLoading(true);
      fetch("https://backend.topuphero.com/api/admin/insert-product", {
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(product),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data.result) {
            toast.success("Your new product is added.");
          } else {
            toast.error(data?.message);
          }
        });
    } else {
      toast.error("Fill up the form properly.");
    }
  };
  return (
    <section className="flex flex-col mx-auto w-[90%] py-10 items-center justify-center ">
      <div className="border-2 border-black rounded-lg p-5">
        <h2 className="text-2xl mb-5 text-gray-900 font-bold">
          Add New Product
        </h2>
        <div className="">
          <form className=" flex flex-col">
            <div>
              <label
                htmlFor="name"
                className="text-[19px] font-semibold text-gray-900"
              >
                Product Name:
              </label>
              <input
                type="text"
                autoComplete="off"
                ref={nameRef}
                name="name"
                placeholder="Enter Product Name Here"
                className="input w-full text-gray-900 text-[18px] border-2 border-primary max-w-xs"
              />
            </div>
            <div className="py-3">
              {" "}
              <label
                htmlFor="image"
                className="text-[19px] font-semibold text-gray-900"
              >
                Image URL:
              </label>
              <input
                autoComplete="off"
                ref={imageUrlRef}
                type="text"
                name="image"
                placeholder="Enter Product Image URL Here"
                className="input w-full text-gray-900 text-[18px] border-2 border-primary max-w-xs"
              />
            </div>
            <div className="py-3">
              <label
                htmlFor="name"
                className="text-[19px] font-semibold text-gray-900"
              >
                Will be active?
              </label>
              <select
                onChange={(e) => setActive(e.target.value)}
                className="select text-gray-900 text-[15px] select-primary w-full max-w-xs"
              >
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="py-3">
              <input
                onClick={addNewProduct}
                type="button"
                value="Add"
                className="input bg-primary w-full text-white cursor-pointer font-semibold text-[18px] border-2 border-primary max-w-xs"
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default AddNewProducts;
