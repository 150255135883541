import React, { useEffect, useState } from "react";
import UpdateTopupOrderStatus from "../Modals/UpdateTopupStatus";
import AddTopupBalance from "../Modals/AddTopupBalance";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
function AllTopupOrders() {
  const [user] = useAuthState(auth);
  const [alltopup, setAllTopUp] = useState([]);
  const [status, setStatus] = useState("Pending");
  const [update, setUpdate] = useState(""); // to update the result depending on admin's update status
  const [order, setOrder] = useState(null);
  const [insert, setInsert] = useState(null);
  useEffect(() => {
    fetch(
      `https://backend.topuphero.com/api/admin/topup-orders?status=${status}&adminEmail=${user?.email}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setAllTopUp(data.result));
  }, [status, update, user?.email]);
  // let total = 0;
  // alltopup.map((top) => (total = total + top.amount));
  // console.log(total);
  return (
    <section className="pt-2 w-[90%] text-gray-900 mx-auto rounded-md">
      <div className="mt-10 md:mt-10 border-t border-gray-200 md:border-none">
        <div className="!px-0 md:!px-5 md:my-7">
          <div className="bg-white flex flex-col">
            <div className="border-gray-200 md:rounded-md pt-4 relative">
              <div className="flex justify-between items-center">
                <p className="font-semibold py-1 px-4 rounded-full mb-3 text-primary text-2xl ">
                  Topup Orders
                </p>
                <p className="font-semibold py-1 px-4 rounded-full mb-3 text-gray-800 text-lg">
                  <select
                    onChange={(e) => setStatus(e.target.value)}
                    className="select text-gray-900 text-[15px] select-primary w-full max-w-xs"
                  >
                    <option selected>Pending</option>
                    <option>Paid</option>
                    <option>Cancelled</option>
                  </select>
                </p>
              </div>
              {alltopup.length === 0 ? (
                <div className="pb-10 mt-10 text-center text-2xl font-semibold font-serif">
                  No new topup request. Everyone is paid.
                </div>
              ) : (
                <div className="">
                  <div className="overflow-x-auto">
                    <table className="min-w-full bg-white  text-xl">
                      <thead className="">
                        <tr className="text-left">
                          <th className="p-3 border">Index</th>
                          <th className="p-3 border">Email</th>
                          <th className="p-3 border">Name</th>
                          <th className="p-3 border">Payment</th>
                          <th className="p-3 border">Amount</th>
                          <th className="p-3 border">Sender Number</th>
                          <th className="p-3 border">Transaction ID</th>
                          {status === "Pending" && (
                            <th className="p-3 border">Action</th>
                          )}
                          <th className="p-3 border">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {alltopup?.map((each, Index) => (
                          <tr
                            key={each._id}
                            className="border-b  border-opacity-20 "
                          >
                            <td className="p-3 border">
                              <p>{Index + 1}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.email}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.name}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.paymentWay ? each.paymentWay : "Old"}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.amount}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.senderNumber}</p>
                            </td>
                            <td className="p-3 border">
                              <p>{each.transactionID}</p>
                            </td>
                            {/* to clear pay the top */}
                            {status === "Pending" && (
                              <td className="p-3 border">
                                <label
                                  htmlFor="insert-balance-now"
                                  onClick={() => setInsert(each)}
                                  className="bg-red-300 rounded-lg px-2 cursor-pointer py-1 w-fit"
                                >
                                  Clear
                                </label>
                              </td>
                            )}

                            {/* to update status */}
                            <td className="p-3 border">
                              {each.statusThree === "Paid" ? (
                                "Paid"
                              ) : each.statusThree === "Cancelled" ? (
                                "Cancelled"
                              ) : (
                                <label
                                  htmlFor="update-status-of-topup-order"
                                  onClick={() => setOrder(each)}
                                  className="bg-red-300 rounded-lg px-2 cursor-pointer py-1 w-fit"
                                >
                                  Update
                                </label>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {order && <UpdateTopupOrderStatus order={order} setUpdate={setUpdate} />}
      {insert && <AddTopupBalance order={insert} setUpdate={setUpdate} />}
    </section>
  );
}

export default AllTopupOrders;
