import React, { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import LoadingPage from "../Shared/LoadingPage";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
function InsertTopup() {
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const transaction = useRef();
  const amount = useRef();
  if (loading) {
    return <LoadingPage />;
  }
  //   to add new product
  const regexForNumber = /^-?\d+\.?\d*$/;
  const insertBalance = (e) => {
    e.preventDefault();
    const balance = amount.current.value;
    const transactionID = transaction.current.value;
    if (regexForNumber.test(balance) && transactionID) {
      const convertedBalance = parseInt(balance);
      const details = {
        balance: convertedBalance,
        topupID: transactionID,
        adminEmail: user?.email,
      };
      setLoading(true);
      fetch(`https://backend.topuphero.com/admin/api/insert-balance`, {
        method: "PATCH",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(details),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoading(false);
          if (data.result) {
            toast.success("Topup balance added to the user's wallet.");
          } else {
            toast.error(data?.message);
          }
        });
    } else {
      toast.error("Fill up the form properly.");
    }
  };
  return (
    <section className="flex flex-col mx-auto w-[90%] py-10 items-center justify-center ">
      <div className="border-2 border-black rounded-lg p-5">
        <h2 className="text-2xl mb-5 text-gray-900 font-bold">
          Insert Balance to the user's wallet
        </h2>
        <div className="">
          <form className=" flex flex-col">
            <div>
              <label
                htmlFor="name"
                className="text-[19px] font-semibold text-gray-900"
              >
                Balance:
              </label>
              <input
                type="text"
                autoComplete="off"
                ref={amount}
                name="name"
                placeholder="Enter Balance Here"
                className="input w-full text-gray-900 text-[18px] border-2 border-primary max-w-xs"
              />
            </div>
            <div className="py-3">
              {" "}
              <label
                htmlFor="image"
                className="text-[19px] font-semibold text-gray-900"
              >
                Topup ID:
              </label>
              <input
                ref={transaction}
                autoComplete="off"
                type="text"
                name="image"
                placeholder="Enter topup Id Here"
                className="input w-full text-gray-900 text-[18px] border-2 border-primary max-w-xs"
              />
            </div>
            <div className="py-3">
              <input
                onClick={insertBalance}
                type="button"
                value="Insert"
                className="input bg-primary w-full text-white cursor-pointer font-semibold text-[18px] border-2 border-primary max-w-xs"
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default InsertTopup;
