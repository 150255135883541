import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../image/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import FreeFireIDGame from "../Games/FreeFireIDGame";
import FreeFireINGame from "../Games/FreeFireINGame";
import FreeFirePromo from "../Games/FreeeFirePromo";
import PubgMobile from "../Games/PubgMobile";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import FreeFireInGameAcc from "../AccountInfo/FreeFireInGameAcc";
import LoadingPage from "./LoadingPage";
import {
  useGetProductQuery,
  useGetProfileQuery,
  useOrderProductMutation,
} from "../../rtk/features/api/apiSlice";
import FreeFireIndonesia from "../Games/FreeFireIndonesia";
import LessIsMore from "../Games/LessIsMore";
import UnipinVoucher from "../Games/UnipinVoucher";

function BuyProduct() {
  const [user] = useAuthState(auth);
  const [recharge, setRecharge] = useState("");
  const [money, setMoney] = useState(0);
  const [playerID, setPlayerID] = useState("");
  // to get info for free fire ingame
  const [emailOrNumber, setEmailOrNumber] = useState("");
  const [password, setPassword] = useState("");
  const [backupCode, setBackupCode] = useState("");
  const [optionValue, setOptionValue] = useState("");
  // loader
  const [loading] = useState(false);
  // get id from params
  const { id } = useParams();
  const navigate = useNavigate();
  // to get game details
  const { data, isLoading } = useGetProductQuery(id);
  const { result: product } = data || {};
  // to get profile details
  const { data: myProfile, isLoading: loadingTwo } = useGetProfileQuery(
    user?.email
  );
  const { result: profile } = myProfile || {};
  // to order game
  const [
    orderProduct,
    {
      isLoading: isOrderProductLoading,
      isSuccess: isOrderProductSucess,
      isError,
    },
  ] = useOrderProductMutation();

  useEffect(() => {
    if (isOrderProductSucess) {
      toast.success("Your order is received.");
      navigate("/order");
    }
    if (isError) {
      toast.error("Please input valid details.");
    }
  }, [isOrderProductSucess, navigate, isError]);

  if (loading || isLoading || loadingTwo || isOrderProductLoading) {
    return <LoadingPage />;
  }

  let date = new Date();
  date = date.toLocaleString();
  // buy now button
  const buyNow = (e) => {
    e.preventDefault();
    if (!user) {
      toast.error("Please login first to order.");
      return navigate("/login");
    }
    if (!money || !recharge) {
      return toast.error("Select the recharge package please");
    }
    if (profile?.balance === 0 || profile?.balance < money) {
      return toast.error("Please recharge balance to your wallet.");
    }
    const orderDetails = {
      productName: product.name,
      playerID: playerID,
      // for freefire ingame
      optionValue: optionValue,
      emailOrNumber: emailOrNumber,
      password: password,
      backupCode: backupCode,
      requestEmail: profile?.email,
      recharge: recharge,
      money: money,
      service: optionValue,
      paid: `${product?.name !== "UNIPIN VOUCHER" ? false : true}`,
      statusTwo: `${product?.name !== "UNIPIN VOUCHER" ? "Pending" : "Paid"}`,
      date: date,
      status: `${
        product?.name !== "UNIPIN VOUCHER"
          ? "এখনো পরিশোধ করা হয়নি।"
          : "পরিশোধ করা হইয়াছে।"
      }`,
    };
    orderProduct(orderDetails);

    // fetch("https://backend.topuphero.com/api/insert-product-order", {
    //   method: "POST",
    //   headers: {
    //     authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //     "content-type": "application/json",
    //   },
    //   body: JSON.stringify(orderDetails),
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setLoading(false);
    //     if (data.result) {
    //       toast.success("Your order is received.");
    //     } else {
    //       toast.error("Please input valid details.");
    //     }
    //   });
    // navigate("/order");
  };
  // add money button
  const addMoney = (e) => {
    e.preventDefault();
    navigate("/add-money");
  };
  return (
    <section className="bg-gray-100 py-5 ">
      <div className="w-[90%] md:pt-[40px] relative mx-auto">
        <div className="w-full text-gray-900 md:w-[750px] md:mx-auto">
          {product?.active === false && (
            <span className="absolute font-bold text-[10px] md:text-xl right-0 top-1 md:top-9 lg:top-10 2xl:top-18 text-primary">
              &#9888; Sorry, this product is out of stock now.
            </span>
          )}
          <h1 className="text-xl font-bold mb-4">{product?.name}</h1>
          <div className="relative">
            <div>
              {/* Account Info */}
              {product?.name !== "UNIPIN VOUCHER" && (
                <div className="wrapper">
                  <div className="order_box_top">
                    <div className="serial_number">1</div>
                    <h5 className="title">Account Info</h5>
                  </div>
                  {product?.name === "FREE FIRE INGAME" ? (
                    <FreeFireInGameAcc
                      setBackupCode={setBackupCode}
                      setEmailOrNumber={setEmailOrNumber}
                      setPassword={setPassword}
                      setOptionValue={setOptionValue}
                      optionValue={optionValue}
                    />
                  ) : (
                    <div className="order_box_bottom">
                      <div className="">
                        <div>
                          <div className="inline-block w-full">
                            <label className="relative block w-full">
                              <span className="text-[14px]  text-slate-600  font-semibold mb-1 inline-block select-none ">
                                Player Id
                              </span>
                              <div className="relative inline-block w-full">
                                <input
                                  autoComplete="off"
                                  onChange={(e) => setPlayerID(e.target.value)}
                                  type="text"
                                  name="playerid"
                                  className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500   px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full"
                                  placeholder="Enter player id"
                                />
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* Select Rechargce */}
              <div className="">
                <div className="order_box_top">
                  <div className="serial_number">
                    {product?.name !== "UNIPIN VOUCHER" ? "2" : "1"}
                  </div>
                  <h5 className="title">Select Recharge</h5>
                </div>
              </div>
              {/* 
              Condition for each game
               */}
              {/* IF the selcted game is Free Fire ID Game */}
              {product?.name === "FREE FIRE IDCODE" && (
                <FreeFireIDGame
                  setMoney={setMoney}
                  setRecharge={setRecharge}
                  money={money}
                  recharge={recharge}
                  balance={profile?.balance || 0}
                />
              )}
              {/* IF the selcted game is Free Fire IN Game */}
              {product?.name === "FREE FIRE INGAME" && (
                <FreeFireINGame
                  setMoney={setMoney}
                  setRecharge={setRecharge}
                  money={money}
                  recharge={recharge}
                  balance={profile?.balance || 0}
                />
              )}
              {/* IF the selcted game is Free Fire Promo */}
              {product?.name === "FREE FIRE PROMO" && (
                <FreeFirePromo
                  setMoney={setMoney}
                  setRecharge={setRecharge}
                  money={money}
                  recharge={recharge}
                  balance={profile?.balance || 0}
                />
              )}
              {/* IF the selcted game is Free Fire ID Game */}
              {product?.name === "PUBG MOBILE" && (
                <PubgMobile
                  setMoney={setMoney}
                  setRecharge={setRecharge}
                  money={money}
                  recharge={recharge}
                  balance={profile?.balance || 0}
                />
              )}

              {/* IF the selcted game is FREE FIRE INDONESIA */}
              {product?.name === "FREE FIRE INDONESIA" && (
                <FreeFireIndonesia
                  setMoney={setMoney}
                  setRecharge={setRecharge}
                  money={money}
                  recharge={recharge}
                  balance={profile?.balance || 0}
                />
              )}
              {/* IF the selcted game is Less is more Game */}
              {product?.name === "LESS IS MORE" && (
                <LessIsMore
                  setMoney={setMoney}
                  setRecharge={setRecharge}
                  money={money}
                  recharge={recharge}
                  balance={profile?.balance || 0}
                />
              )}
              {/* IF the selcted game is Unipin Voucher Game */}
              {product?.name === "UNIPIN VOUCHER" && (
                <UnipinVoucher
                  setMoney={setMoney}
                  setRecharge={setRecharge}
                  money={money}
                  recharge={recharge}
                  balance={profile?.balance || 0}
                />
              )}

              {/* Select Payment */}
              <div className="wrapper">
                <div className="order_box_top">
                  <div className="serial_number">
                    {" "}
                    {product?.name !== "UNIPIN VOUCHER" ? "3" : "2"}
                  </div>
                  <h5 className="title">Select Payment</h5>
                </div>
                <div className="order_box_bottom">
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-2.5 md:gap-3">
                    {/* Wallet */}
                    <div className="rounded-lg border-2 py-4 border-primary relative">
                      <div className="px-1 md:px-2 flex items-center w-full">
                        <div className="flex !text-primary-500 justify-center gap-2 items-center w-full">
                          <span className="_body2 !text-primary-500 !text-sm md:!text-base font-semibold text-center">
                            <div className="p-1.5 bg-white pb-2.5">
                              <img
                                className="w-full h-auto"
                                src={logo}
                                alt="logo"
                              />
                            </div>
                            <span className="!text-xs !font-normal text-primary-500">
                              EasyTopup Wallet
                            </span>
                          </span>
                        </div>
                      </div>
                      {/* Check */}
                      <FontAwesomeIcon
                        className="absolute top-0 left-[-2px] rounded-tl-lg bg-primary py-1 px-2 text-white"
                        icon={faCheck}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Button */}
              <div className="flex justify-end gap-3">
                <button
                  onClick={addMoney}
                  className="border-primary text-primary px-3 py-2 text-[14px] text-sm rounded-md duration-200 ease-in-out font-medium border inline-block"
                  type="button"
                >
                  <span className="flex items-center h-full w-full justify-center gap-1.5 ">
                    Add Money
                  </span>
                </button>
                <button
                  disabled={product?.active === false}
                  onClick={buyNow}
                  className={`${
                    product?.active ? "bg-primary" : "bg-gray-400"
                  } text-white px-3 py-2 text-[14px] text-sm rounded-md duration-200 ease-in-out font-medium border outline-none no-underline select-none relative inline-block`}
                  type="submit"
                >
                  <span className="flex items-center h-full w-full justify-center gap-1.5 ">
                    Buy Now
                  </span>
                </button>
              </div>
              {/* Rules and Conditions */}
              <div className="wrapper">
                <div className="order_box_top">
                  <div className="serial_number">
                    {" "}
                    {product?.name !== "UNIPIN VOUCHER" ? "4" : "3"}
                  </div>
                  <h5 className="title">Rules &amp; Conditions</h5>
                </div>
                <div className="order_box_bottom">
                  <div>
                    {product?.name === "FREE FIRE INGAME" && (
                      <>
                        <li>
                          অর্ডার Cancel হলে কি কারণে হয়েছে My Orders এ দেওয়া
                          আছে। সমস্যাটির সমাধান করে আবার অর্ডার করুন।
                        </li>
                        <li>
                          ডেলিভারি টাইম সাধারণত 20 মিনিট, ইভেন্ট থাকলে ২ ঘণ্টা
                          বা তার ও বেশি সময় লাগতে পারে।
                        </li>
                        <li>
                          জিমেইল আইডি এর ব্যাকাপ কোড দিয়ে অর্ডার করুণ, ব্যাকাপ
                          কোড কিভাবে পাবেন না জানলে{" "}
                          <a
                            className="text-primary"
                            rel="noreferrer"
                            href={`${
                              optionValue === "Facebook"
                                ? "https://www.youtube.com/watch?v=VGWgN7CGgDQ"
                                : "https://www.youtube.com/watch?v=tNWkW0C7lNQ"
                            }`}
                            target="_blank"
                          >
                            Watch Video{" "}
                          </a>
                          লেখার উপর ক্লিক করুন।
                        </li>
                        <li>
                          আইডিতে ডায়মন্ড না গেলে এবং অর্ডার কম্পিলট দেখালে
                          অবশ্যই পাসওয়ার্ড চেন্জ করার পুর্বে যোগাযোগ করতে হবে।
                          পাসওয়ার্ড চেন্জ করে ফেললে আর কোন অভিযোগ গ্রহন করা হবে
                          না। কারন এক নাম্বার এবং আলাদা আলাদা পাসওয়ার্ড দিয়ে
                          একাধিক একাউন্ট অনেকের থাকে।
                        </li>
                      </>
                    )}
                    {product?.name === "FREE FIRE IDCODE" && (
                      <>
                        <li>
                          শুধুমাত্র Bangladesh সার্ভারে ID Code দিয়ে টপ আপ হবে।
                        </li>
                        <li>
                          Player ID Code ভুল দিয়ে Diamond না পেলে{" "}
                          <b> EasytopupBD </b>
                          কর্তৃপক্ষ দায়ী নয় ।
                        </li>
                        <li>
                          ID Code দিয়ে Order করলে এবং ডায়মন্ড না গেলে চেক করার
                          জন্য ID Pass দিতে হবে।
                        </li>
                        <li>
                          অর্ডার Canceled হলে কি কারণে হয়েছে My Orders এ দেওয়া
                          আছে। অনুগ্রহ পুর্বক দেখে পুনরায় সঠিক তথ্য দিয়ে অর্ডার
                          করবেন।
                        </li>
                        <li>
                          ডেলিভারি টাইম সাধারণত ৩০ মিনিট, ইভেন্ট থাকলে ২ ঘণ্টা
                          বা তার ও বেশি সময় লাগতে পারে। মাঝে মাঝে আইডি তে সমস্যা
                          হলে যেমন লগিন করতে না পারলে, পাসওয়ার্ড ভুল দিলে,
                          সার্ভারে সমস্যা থাকলে অথবা স্টক না থাকলে সর্বোচ্চ ২৪
                          ঘন্টা পর্যন্ত লাগতে পারে।
                        </li>
                      </>
                    )}
                    {product?.name === "FREE FIRE PROMO" && (
                      <>
                        <li>
                          শুধুমাত্র Bangladesh সার্ভারে ID Code দিয়ে টপ আপ হবে।
                        </li>
                        <li>
                          Player ID Code ভুল দিয়ে Diamond না পেলে{" "}
                          <b> EasytopupBD </b>
                          কর্তৃপক্ষ দায়ী নয় ।
                        </li>
                        <li>
                          ID Code দিয়ে Order করলে এবং ডায়মন্ড না গেলে চেক করার
                          জন্য ID Pass দিতে হবে।
                        </li>
                        <li>
                          অর্ডার Canceled হলে কি কারণে হয়েছে My Orders এ দেওয়া
                          আছে। অনুগ্রহ পুর্বক দেখে পুনরায় সঠিক তথ্য দিয়ে অর্ডার
                          করবেন।
                        </li>
                        <li>
                          ডেলিভারি টাইম সাধারণত ৩০ মিনিট, ইভেন্ট থাকলে ২ ঘণ্টা
                          বা তার ও বেশি সময় লাগতে পারে। মাঝে মাঝে আইডি তে সমস্যা
                          হলে যেমন লগিন করতে না পারলে, পাসওয়ার্ড ভুল দিলে,
                          সার্ভারে সমস্যা থাকলে অথবা স্টক না থাকলে সর্বোচ্চ ২৪
                          ঘন্টা পর্যন্ত লাগতে পারে।
                        </li>
                      </>
                    )}
                    {product?.name === "FREE FIRE INDONESIA" && (
                      <>
                        শুধুমাত্র Indonesia সার্ভারে ID Code দিয়ে টপ আপ হবে
                        Player ID Code ভুল দিয়ে Diamond না পেলে{" "}
                        <b> EasytopupBD </b>
                        কর্তৃপক্ষ দায়ী নয় । ID Code দিয়ে Order করলে এবং
                        ডায়মন্ড না গেলে চেক করার জন্য ID Pass দিতে হবে।
                      </>
                    )}
                    {product?.name === "PUBG MOBILE" && (
                      <>
                        <li>
                          Player ID Code ভুল দিয়ে Diamond না পেলে{" "}
                          <b> EasytopupBD </b>
                          কর্তৃপক্ষ দায়ী নয় ।
                        </li>
                        <li>
                          ID Code দিয়ে Order করলে এবং ডায়মন্ড না গেলে চেক করার
                          জন্য ID Pass দিতে হবে।
                        </li>
                        <li>
                          অর্ডার Canceled হলে কি কারণে হয়েছে My Orders এ দেওয়া
                          আছে। অনুগ্রহ পুর্বক দেখে পুনরায় সঠিক তথ্য দিয়ে অর্ডার
                          করবেন।
                        </li>
                        <li>
                          ডেলিভারি টাইম সাধারণত ৩০ মিনিট, ইভেন্ট থাকলে ২ ঘণ্টা
                          বা তার ও বেশি সময় লাগতে পারে। মাঝে মাঝে আইডি তে সমস্যা
                          হলে যেমন লগিন করতে না পারলে, পাসওয়ার্ড ভুল দিলে,
                          সার্ভারে সমস্যা থাকলে অথবা স্টক না থাকলে সর্বোচ্চ ২৪
                          ঘন্টা পর্যন্ত লাগতে পারে।
                        </li>
                      </>
                    )}
                    {product?.name === "LESS IS MORE" && (
                      <>
                        <li>
                          Less is More আর্ডার করলে আপনার আইডিতে ৫০ ডায়মন্ড এর
                          নিচে থাকতে হবে। অন্যথায় অর্ডার Cancel হয়ে যাবে।
                          ধন্যবাদ।
                        </li>
                      </>
                    )}
                    {product?.name === "UNIPIN VOUCHER" && (
                      <>
                        <b>
                          How To Top Up Diamond With Unipin Voucher BD (UC):
                        </b>
                        <h4>
                          <b> 1. </b> প্রথমে কত ডায়মন্ড নিতে চান সে অনুযায়ী{" "}
                          <b>Unipin Voucher</b> পিন সিলেক্ট করুন ।
                        </h4>
                        <h4>
                          <b>2.</b> পিন পাওয়ার পর <b>shop.garena.my</b>{" "}
                          ওয়েবসাইটে যান ।
                        </h4>
                        <h4>
                          <b>3. Free Fire</b> অপশনে গিয়ে আপনার <b>Player ID</b>{" "}
                          দিয়ে লগিন করুন ।
                        </h4>
                        <h4>
                          <b>4.</b> এরপর <b> Proccess to Payment</b> ক্লিক করে
                          কত ডায়মন্ড টপআপ করতে চান সেটা সিলেক্ট করে পিন দিয়ে
                          Purchase করে নিন ।
                        </h4>
                        <b>UniPin Voucher Bangladesh:</b>
                        <h4>
                          For many mobile gamers, a game voucher is a great
                          complement to enhance their mobile gaming experience.
                          You can purchase premium game content using a UniPin
                          voucher to accessorize your game character and open
                          various game modes for a more exciting gaming
                          experience. <br />
                          Buy UniPin vouchers only in UniPin for fast and easy
                          transactions. <br />
                          All you need to do is choose the denomination and the
                          payment option you want, then complete the
                          transaction. Once the transaction is finished, the
                          UniPin voucher will be added instantly to your
                          account.
                          <br />
                          With various online payment options available, UniPin
                          becomes a great alternative for gamers, wherever they
                          are located.
                        </h4>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BuyProduct;
