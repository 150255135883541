import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import ConfirmAdmin from "../Modals/ConfirmAdmin";

function AllUsers() {
  const [modal, setModal] = useState(null);
  const [user] = useAuthState(auth);
  const [allUser, setAllUser] = useState([]);
  useEffect(() => {
    fetch(
      `https://backend.topuphero.com/api/admin/all-users?adminEmail=${user?.email}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setAllUser(data.result));
  }, [user, modal]);
  // to make someone new admin

  return (
    <section className="pt-2 w-[90%] text-gray-900 mx-auto rounded-md">
      <div className="mt-10 md:mt-10 border-t border-gray-200 md:border-none">
        <div className="!px-0 md:!px-5 md:my-7">
          <div className="bg-white">
            <div className="md:border border-gray-200 md:rounded-md pt-4 relative">
              <p className="_subtitle2 font-semibold py-1 px-4 rounded-full mb-3 text-primary text-2xl">
                All Users
              </p>
              <div className="p-2 mx-auto sm:p-4 ">
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white  text-xl">
                    <thead className="">
                      <tr className="text-left">
                        <th className="p-3 border">Index</th>
                        <th className="p-3 border">Email</th>
                        <th className="p-3 border">Status</th>
                        <th className="p-3 border">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allUser?.map((each, Index) => (
                        <tr
                          key={each._id}
                          className="border-b  border-opacity-20 "
                        >
                          <td className="p-3 border">
                            <p>{Index + 1}</p>
                          </td>
                          <td className="p-3 border">
                            <p>{each.email}</p>
                          </td>
                          <td className="p-3 border">
                            <p>{each.isAdmin ? "Admin" : "User"}</p>
                          </td>
                          <td className="p-3 border">
                            <p>
                              <label
                                htmlFor="make-admin"
                                onClick={() => setModal(each)}
                                className={`${
                                  each.isAdmin ? "bg-red-800" : "bg-green-800"
                                } px-2 py-1 cursor-pointer rounded-full text-[15px] text-white`}
                              >
                                {each.isAdmin ? "Delete Admin" : "Make Admin"}
                              </label>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* to show modal */}
            {modal && <ConfirmAdmin user={modal} setModal={setModal} />}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AllUsers;
