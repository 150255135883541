import React from "react";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";
import auth from "../../firebase.init";
import AddBalanceRules from "./AddBalanceRules";
import LoadingPage from "../Shared/LoadingPage";
import Clipboard from "react-clipboard.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import {
  useGetPaymentSystemQuery,
  useOrderTopupMutation,
} from "../../rtk/features/api/apiSlice";

function AddBalance() {
  const [user] = useAuthState(auth);
  const [amount, setAmount] = useState(null);
  const [senderNumber, setSenderNumber] = useState(null);
  const [transId, setTransId] = useState(null);
  const [copied, setCopied] = useState(false);
  // for payments way
  const { data, isLoading } = useGetPaymentSystemQuery();
  const { result: paymentWays } = data || {};
  const [paymentName, setPaymentName] = useState("Upay");
  const [paymentNumber, setPaymentNumber] = useState("01311069735");
  const [
    orderTopup,
    { isLoading: isOrderTopupLoading, isSuccess: isOrderTopupSucess, isError },
  ] = useOrderTopupMutation();

  useEffect(() => {
    if (isOrderTopupSucess) {
      toast.success("Your topup order is received.");
    }
    if (isError) {
      toast.error("Please input valid details.");
    }
  }, [isOrderTopupSucess, isError]);
  if (isLoading || isOrderTopupLoading) {
    return <LoadingPage />;
  }
  // to copied to clipboard
  const copy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 500);
  };

  const addMoney = (e) => {
    e.preventDefault();
    if (!amount && !senderNumber && !transId) {
      toast.error("Fill the form first.");
    }
    // to get date
    let date = new Date();
    date = date.toLocaleString();
    // to check the amount is number or not
    const regexForNumber = /^-?\d+\.?\d*$/;
    if (regexForNumber.test(amount) && regexForNumber.test(senderNumber)) {
      const money = parseInt(amount);
      const details = {
        email: user?.email,
        date: date,
        name: user?.displayName,
        amount: money,
        senderNumber: senderNumber,
        transId: transId,
        paymentWay: paymentName,
        status: "Unpaid",
        statusTwo: "এখনো পরিশোধ করা হয়নি।",
        statusThree: "Pending",
      };
      orderTopup(details);
    }
  };
  return (
    <section className="mt-[140px] text-gray-900">
      <div className="w-[90%] sm:w-[600px] mx-auto my-7">
        <div>
          <div className="mb-5">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2.5 md:gap-3">
              {paymentWays?.map((each) => (
                <div
                  key={each._id}
                  onClick={() => {
                    setPaymentName(each.paymentName);
                    setPaymentNumber(each.paymentNumber);
                  }}
                  className="rounded cursor-pointer select-none duration-200 border relative flex items-stretch border-gray-200 hover:border-gray-400 justify-center "
                >
                  <div className="px-1 md:px-2 flex items-center w-full">
                    <div className="flex  justify-center gap-2 items-center w-full">
                      <span className="!text-sm md:!text-base font-semibold text-center">
                        <img src={each.image} alt={each.paymentName} />
                        <span className="!text-xs !font-normal text-primary-500">
                          {each.paymentName}
                        </span>
                      </span>
                    </div>
                  </div>
                  {paymentName === each.paymentName && (
                    <FontAwesomeIcon
                      className="absolute top-[-1px] left-[-1px] rounded-tl-sm bg-primary py-1 px-2 text-white"
                      icon={faCheck}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <Outlet /> */}
        <div className="mb-3 flex items-center justify-between flex-wrap gap-3 border-l-4 border-l-primary bg-primary/20 border-primary-500 py-2 px-3.5">
          <p className="">
            {paymentName}: {paymentNumber}
          </p>
          <button
            onClick={copy}
            className="primary px-3 py-2 text-[14px] text-sm rounded-md duration-200 ease-in-out font-medium border bg-primary text-white outline-none no-underline select-none inline-block disabled:opacity-50 disabled:pointer-events-none active:opacity-60 small relative"
          >
            <span className="flex items-center h-full w-full justify-center gap-1.5 ">
              <Clipboard data-clipboard-text={paymentNumber}>
                {copied ? "Number Copied" : "Copy Number"}
              </Clipboard>
            </span>
          </button>
        </div>
        {/* Rules */}
        <AddBalanceRules />

        {/* Forms */}
        <div className="mt-6">
          <div className="">
            <form className="flex flex-col gap-4">
              <div>
                <div className="inline-block w-full">
                  <label className="relative block w-full">
                    <span className="text-[14px]  text-slate-500  font-semibold mb-1 inline-block select-none ">
                      Amount
                    </span>
                    <div className="relative inline-block w-full">
                      <input
                        required
                        autoComplete="off"
                        type="text"
                        onChange={(e) => setAmount(e.target.value)}
                        name="amount"
                        className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer"
                        placeholder="Amount"
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div>
                <div className="inline-block w-full">
                  <label className="relative block w-full">
                    <span className="text-[14px]  text-slate-500  font-semibold mb-1 inline-block select-none ">
                      Sender Number
                    </span>
                    <div className="relative inline-block w-full">
                      <input
                        type="text"
                        autoComplete="off"
                        required
                        onChange={(e) => setSenderNumber(e.target.value)}
                        name="number"
                        className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer"
                        placeholder="Sender Number"
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div>
                <div>
                  <div className="inline-block w-full">
                    <label className="relative block w-full">
                      <span className="text-[14px]  text-slate-500  font-semibold mb-1 inline-block select-none ">
                        Transaction Id
                      </span>
                      <div className="relative inline-block w-full">
                        <input
                          type="text"
                          autoComplete="off"
                          required
                          onChange={(e) => setTransId(e.target.value)}
                          name="transaction_id"
                          className="bg-white border-slate-300 hover:border-slate-400 focus:border-primary-500  px-3 py-2 text-[14px] rounded-md duration-200 ease-in-out font-medium focus:ring-1 ring-primary-100  border outline-none inline-block disabled:opacity-50 disabled:pointer-events-none text-slate-700 placeholder:text-slate-400 w-full peer   "
                          placeholder="Transaction Id"
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <button
                onClick={addMoney}
                className="primary bg-primary text-white px-3 py-2 text-[14px] text-sm rounded-md duration-200 ease-in-out font-medium border outline-none no-underline select-none inline-block disabled:opacity-50 disabled:pointer-events-none active:opacity-60 w-full  relative"
                type="submit"
              >
                <span className="flex items-center h-full w-full justify-center gap-1.5 ">
                  Add Money
                </span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddBalance;
