import React from "react";

function AddBalanceRules() {
  return (
    <section>
      <div className="order_box_body">
        <div>
          <p className="_subtitle1 text-gray-800 font-semibold">Step 1:</p>
          <div className="pl-2 mt-2 space-y-2.5">
            <p className="flex items-start gap-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 8 16"
                className="text-gray-500"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                ></path>
              </svg>{" "}
              প্রথমে উপরে দেওয়া নাম্বার কপি করুন।
            </p>
            <p className="flex items-start gap-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 8 16"
                className="text-gray-500"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                ></path>
              </svg>{" "}
              (bKash,Nagad,Rocket) App অথাবা USSD কোডের মাধ্যমে সেন্ড মানি অপশন
              সিলেক্ট করুন।
            </p>
            <p className="flex items-start gap-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 8 16"
                className="text-gray-500"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                ></path>
              </svg>{" "}
              EasyTopupbd WALLET নাম্বার (_) প্রবেশ করুন।
            </p>
            <p className="flex items-start gap-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 8 16"
                className="text-gray-500"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                ></path>
              </svg>{" "}
              এম্যাউন্ট অর্থাৎ কত টাকা যোগ করবেন তার পরিমাণ দিন।
            </p>
            <p className="flex items-start gap-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 8 16"
                className="text-gray-500"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                ></path>
              </svg>{" "}
              রেফারেন্সে আপনার ইউজার আইডি দিন।
            </p>
            <p className="flex items-start gap-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 8 16"
                className="text-gray-500"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                ></path>
              </svg>{" "}
              আপনার বিকাশ পিন নাম্বার দিন।
            </p>
          </div>
        </div>
        <div className="mt-4">
          <p className="_subtitle1 text-gray-800 font-semibold">Step 2:</p>
          <div className="pl-2 mt-2 space-y-2.5">
            <p className="flex items-start gap-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 8 16"
                className="text-gray-500"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                ></path>
              </svg>{" "}
              নিচে যে দুটি বক্স দেখতে পারছেন প্রথম Box এ কত টাকা পাঠিয়েছেন সেটা
              লিখুন।
            </p>
            <p className="flex items-start gap-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 8 16"
                className="text-gray-500"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                ></path>
              </svg>{" "}
              দ্বিতীয় বক্সে আপনি যে নাম্বার থেকে টাকা পাঠিয়েছেন সেই নাম্বারটি
              লিখুন।
            </p>
            <p className="flex items-start gap-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 8 16"
                className="text-gray-500"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                ></path>
              </svg>{" "}
              তৃতীয় বক্সে বিকাশ থেকে যে মেসেজ পেয়েছেন সেটার ট্রানসাকশান আইডি টি
              এখানে লিখুন।
            </p>
            <p className="flex items-start gap-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 8 16"
                className="text-gray-500"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                ></path>
              </svg>{" "}
              তারপর Submit অপশনে ক্লিক করুন।
            </p>
            <p className="flex items-start gap-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 8 16"
                className="text-gray-500"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                ></path>
              </svg>{" "}
              পাঁচ থেকে দশ মিনিটের মধ্যে টাকা যোগ হয়ে যাবে আপনার ওয়ালেটে।{" "}
            </p>
            <p className="flex items-start gap-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 8 16"
                className="text-gray-500"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M0 8c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
                ></path>
              </svg>{" "}
              অবশ্যই টাকা Send Money করার পর এই কাজটি করবেন।
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddBalanceRules;
