import React from "react";

const LoadingPage = () => {
  return (
    <section className="flex justify-center items-center h-[100vh]">
      <div className="w-16 h-16 border-4 border-primary border-dashed rounded-full animate-spin"></div>
    </section>
  );
};

export default LoadingPage;
