import { useEffect, useState } from "react";

const useToken = (user, name, mobile) => {
  const [token, setToken] = useState("");
  useEffect(() => {
    const email = user?.user?.email;
    if (email) {
      const userDetails = {
        name: name ? name : user?.user?.displayName,
        email: email,
        phone: mobile ? mobile : "+8800",
        imgURL:
          user?.user?.photoURL ||
          "https://img.freepik.com/premium-vector/gamer-youtuber-gaming-avatar-with-headphones-esport-logo_8169-260.jpg",
      };
      fetch(`https://backend.topuphero.com/api/insert-user`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(userDetails),
      })
        .then((res) => res.json())
        .then((data) => {
          setToken(data.token);
          const token = data.token;
          localStorage.setItem("accessToken", token);
        });
    }
  }, [user]);
  return [token];
};
export default useToken;
